@import '~antd/dist/antd.less';
@primary-color: #e11f2d;
@heading-color: #b0de18;
@text-color: #000;
@text-color-secondary: #000;
@text-color-inverse: #fff;
@border-radius-base: 0px;
@table-selected-row-bg: #A5A4A4;
@background-color-base: #7C7D7D;
@border-width-base: 0px;
@font-family: "Open Sans", sans-serif;
@alert-error-bg-color: #A5A5A5;
@link-color: blue;
@item-hover-bg: #e5e5e5;
@success-color: #16891f;

.ant-popover-inner-content,
.ant-popover-title,
.ant-modal-confirm-content,
.ant-modal-confirm-title,
.ant-modal-content,
.ant-input,
.ant-notification-notice-close,
.ant-notification-notice-message,
.ant-notification-notice-content {
  .ant-typography {
    color: grey !important;
  }
}

.ant-btn-ghost {
  color: #a5a4a4;
  span {
    text-decoration: underline;
  }
}
.ant-modal-body {
  padding: 35px;
  border-bottom: 1px #9ea9a7 solid;
}
.ant-modal-header {
  background: #9ea9a7;
  display: flex;
  justify-content: center;
  .ant-modal-title {
    font-weight: 600;
    font-size: 1.2rem;
  }
}

.ant-alert {
  margin: 10px 0px;
}

.ant-modal-footer {
  > button {
    box-shadow: 0 0 0;
  }
  .ant-btn-primary {
    color: white;
  }
}

.ant-alert-error {
  background: #EA7878;
}

.ant-alert-success {
  background: #CDE7C9;
  > span {
    color: #818583;
  }
}

